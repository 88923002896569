<template>
  <v-card flat class="ma-1">
    <v-card-text class="px-10">
      <v-row dense class="my-2">
        <v-col cols="12">
          <span class="title font-weight-bold">{{
            quoteCompany
              ? quoteCompany.name + " Profile Information"
              : "Request to join a Company Account"
          }}</span>
        </v-col>
      </v-row>
      <v-form lazy-validation ref="form">
        <div v-if="!quoteCompany">
          <v-row dense class="pa-0 my-5">
            <v-col cols="12" md="6">
              <span class="subtitle-1 mb-0"
                >Let us know what the name of your company is. We will add you
                to an existing profile or create one for you. We will also need
                some additional information.</span
              >
            </v-col>
          </v-row>
          <v-row dense class="pa-0 ma-0">
            <v-col cols="12" md="6">
              <span class="subtitle-1 mb-0">Company Name</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                name="companyname"
                placeholder="New Company Name"
                type="text"
                autocomplete="off"
                v-model="formCompanyRequest"
                outlined
                dense
                :disabled="!isQuoteIncomplete && !isAdmin"
                class="mt-1 subtitle-1"
                @change="
                  updateUIState({
                    key: 'company_request',
                    value: formCompanyRequest,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formCompanyRequest,
                    vuexValue: quoteUser ? quoteUser.company_request : null,
                  })
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-btn
              v-if="quoteCompany && quoteCompany.nda"
              class="my-5"
              color="secondary"
              @click="onDownloadNDA(quoteCompany.nda)"
              data-test="downloadnda"
              >Download NDA on file</v-btn
            >
            <span v-else class="subtitle-1"
              >To setup a Mutual NDA, email
              <a
                class="warning--text no-underline"
                href="mailto:sales@fixturfab.com"
                >sales@fixturfab.com</a
              ></span
            >
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <BillingAddressForm
              ref="billing_address_form"
              :editMode="editMode"
              :user="quoteUser"
              :company="quoteCompany"
              :quote="quote"
              :billingAddress="billingAddress"
              data-cy="billing-address-form"
            />
          </v-col>
        </v-row>
        <v-row dense class="pa-0 ma-0">
          <v-col cols="12" md="6">
            <v-divider class="my-4" />
            <span class="subtitle-1">Shipping Address</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-checkbox
              v-model="formShippingSame"
              label="Shipping Address same as Billing Address"
              color="secondary"
              dense
              hide-details
              class="mt-0 mb-5 body-1"
              :disabled="!isQuoteIncomplete && !isAdmin"
              @change="updateShippingSame(formShippingSame)"
              data-test="shipping_same"
              data-cy="rfq-user-shipping-same-checkbox"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <transition name="slide">
              <div v-if="!formShippingSame">
                <v-divider />
                <ShippingAddressForm
                  ref="shipping_address_form"
                  :editMode="editMode"
                  :user="quoteUser"
                  :company="quoteCompany"
                  :quote="quote"
                  :shippingAddress="shippingAddress"
                  data-cy="shipping-address-form"
                />
              </div>
            </transition>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "CompanyView",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: null,
    },
    quoteUser: {
      type: Object,
      default: null,
    },
    quoteCompany: {
      type: Object,
      default: null,
    },
    isQuoteIncomplete: {
      type: Boolean,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    billingAddress: {
      type: Object,
      default: null,
    },
    shippingAddress: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formCompanyRequest: this.quoteUser
        ? this.quoteUser.company_request
        : null,
      formShippingSame:
        Object.keys(this.quote).length > 0
          ? this.quote.shipping_same_as_billing
          : true,
    };
  },
  components: {
    BillingAddressForm: () => import("../components/BillingAddressForm.vue"),
    ShippingAddressForm: () => import("../components/ShippingAddressForm.vue"),
  },
  methods: {
    hybridRules({ required }) {
      const rules = [];
      if (required) {
        const rule = (v) => !!v || "Input is required";
        rules.push(rule);
      }
      return rules;
    },
    onSaveUserData(payload) {
      this.$emit("saveUserData", payload);
    },
    onSaveCompanyData(payload) {
      this.$emit("saveCompanyData", payload);
    },
    onDownloadNDA(payload) {
      this.$emit("downloadNDA", payload);
    },
    updateUIState(payload) {
      this.$store.dispatch("ui/updateQuoteUser", payload);
    },
    updateShippingSame(payload) {
      this.$store.dispatch("ui/updateShippingSame", payload);
    },
    isBillingAddressValid() {
      return (
        this.$refs["billing_address_form"] &&
        this.$refs["billing_address_form"].isBillingAddressValid()
      );
    },
    isShippingAddressValid() {
      return (
        this.$refs["shipping_address_form"] &&
        this.$refs["shipping_address_form"].isShippingAddressValid()
      );
    },
  },
  mounted() {
    this.$refs.form.validate();
    if (this.editMode || Object.keys(this.quote).length > 0) {
      this.updateShippingSame(this.quote.shipping_same_as_billing);
    }
  },
};
</script>
